enum MODAL {
  AuthPasswordNew = "modal-auth-password-new",
  AuthPasswordReset = "modal-auth-password-reset",
  AuthRegistration = "modal-auth-registration",
  ClientProfileDelete = "modal-client-profile-delete",
  HeaderNotification = "modal-header-notification",
  ProjectCreate = "modal-project-create",
}

export { MODAL };
