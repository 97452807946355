interface ModalState {
  modal: string | null;
  modalButton: HTMLButtonElement | null;
}

const useModalStore = () => {
  const modalState = useState<ModalState>("modalState", () => {
    return {
      modal: null,
      modalButton: null,
    };
  });

  const closeModal = () => {
    modalState.value.modal = null;

    if (modalState.value.modalButton) {
      modalState.value.modalButton.focus();
      modalState.value.modalButton = null;
    }

    pageScrollUnblock();
  };

  const isModalOpen = (modal: string | null = null) => {
    if (modal) {
      return modalState.value.modal === modal;
    }

    return Boolean(modalState.value.modal);
  };

  const openModal = (modal: string, event: Event | null = null) => {
    pageScrollBlock();

    if (event) {
      modalState.value.modalButton = event.target as HTMLButtonElement;
    }

    modalState.value.modal = modal;
  };

  return {
    closeModal,
    isModalOpen,
    openModal,
  };
};

export { useModalStore };
